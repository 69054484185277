import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Carousel } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }} className="dark">
    <Seo title="Home" />
    <div class="d-flex w-100 mx-auto flex-column">
    <section class="text-center mt-nav" style={{paddingTop: `60px`, paddingBottom:`60px`}} >
        <h1 class="thin">Dental Manufacturing Software</h1>

        <p class="lead p-3" style={{fontWeight:`300`}}>
            Workbench will manage your Dental Manufacturing workflows and increase productivity.
            
        </p>
        <p class="pl-5 pr-5 text-muted mb-5">
            Seamlessly process your IOS and Manual RX into Manufacture Orders.
            Configure workflows and capture Technician tasks and performance with full analytics.
            Connect with compatible Lab Management Systems or add Admin and Invoicing modules for a complete package.
        </p>
        <p class="">
            <Link to="/" className="btn btn-secondary" title="Learn More">
                Get started today
            </Link>

        </p>
    </section>
    <Carousel>
      <Carousel.Item>
        <div style={{width:`830px`, marginLeft: `auto`, marginRight: `auto`}}>
        <StaticImage
          src="../images/IOS.png"
          height={350}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Workbench IOS"
          className="d-block"
        />  
        </div>
        <Carousel.Caption>
          <div class="text-caption">Fully integrated IOS Inbox, get all you RX in one place.</div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div style={{width:`645px`, marginLeft: `auto`, marginRight: `auto`}}>
          <StaticImage
            src="../images/workbench.png"
            height={350}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Workbench Tasks"
            className="d-block"
          />    
        </div>  
        <Carousel.Caption>
        <div class="text-caption">Capacity Work Planning, visualize individual technician tasks and capture progress</div>
      </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div style={{width:`707px`, marginLeft: `auto`, marginRight: `auto`}}>
          <StaticImage
            src="../images/order.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Workbench Orders"
            className="d-block"
            style={{ height:`300px !important`}}
          />
        </div>
        <Carousel.Caption>
          <div class="text-caption">Digital RX and Work Order, files, STL's and images.</div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    <div class="brands mt-5 mb-5 text-muted text-center">
        <p class="mb-3">
            Fully integrated with IOS, Design Services, CAD/CAM and Lab Management
        </p>
        <div class="d-flex justify-content-center mb-2">
            <div class="brand">
                iTero
            </div>

            <div class="brand">
                3Shape
            </div>
            <div class="brand">
                Medit
            </div>
            <div class="brand">
                Carestream
            </div>
            <div class="brand">
                Sirona
            </div>
        </div>
        <div class="d-flex justify-content-center mb-2">
            <div class="brand">
                FullContour
            </div>
            <div class="brand">
                3Shape DS
            </div>
            <div class="brand">
                Exocad
            </div>
            <div class="brand">
                Zirkonzahn
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="brand">
                Labtrac
            </div>
            <div class="brand">
                Inventrix
            </div>
        </div>
    </div>
    </div>
  </Layout>
)

export default IndexPage
